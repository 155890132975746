import { useEffect } from 'react';
import useAnalytics from '../analytics/useAnalytics';
import useFullScreenState from '../../sci-ui-components/FullScreen/useFullScreenState';

function useChartFullScreenState(): [boolean, () => void];
function useChartFullScreenState(noFullScreen: boolean): [boolean, undefined | (() => void)];
function useChartFullScreenState(noFullScreen?: boolean): [boolean, undefined | (() => void)] {
  const result = useFullScreenState(noFullScreen ?? false);
  const { trackEvent } = useAnalytics();
  const [isFullScreen] = result;
  useEffect(() => {
    if (isFullScreen) {
      trackEvent({
        eventName: 'CHARTS_FULLSCREEN_TOGGLED_ON',
      });
    }
  }, [isFullScreen, trackEvent]);
  return result;
}

export default useChartFullScreenState;
