import React, { ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import {
  ComposedChart,
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  YAxisProps,
  XAxisProps,
  Legend,
  ReferenceLine,
} from 'recharts';
import { formatAsDate, formatAsCount } from '../utils/formatters';
import ChartWrapper, { ChartWrapperProps } from '../ChartWrapper/ChartWrapper';
import TimeLineChartTooltip from './TimeLineChartTooltip';
import {
  RenderStatsInTooltipMap,
  RenderStatsInTooltipFn,
  DataPayload,
  RenderPreviewInTooltipFn,
  RenderPreviewInTooltipMap,
} from './types';

import classes from './TimeLineChart.module.scss';
import { isDefined } from 'sci-ui-components/utils/misc';

export type TimeLineChartProps<
  TItem extends object,
  TXAxisValue = any,
  TYAxisValue = number,
  TDataKey extends string | number = string
> = ChartWrapperProps & {
  data: TItem[];
  highlighDataKey?: string | number | null;

  formatXAxisValue?: (value: TXAxisValue) => string;
  formatYAxisValue?: (value: TYAxisValue) => string;
  xAxisDataKey: DataKey<TItem>;
  xAxisProps?: Partial<XAxisProps>;
  yAxisProps?: Partial<YAxisProps>;
  yAxisStartFromMin?: boolean;
  yAxisAllowDecimals?: boolean;

  lines?: LineConfig<TItem, TDataKey>[];
  connectNulls?: boolean;
  withDots?: boolean;
  hideTrendLines?: boolean;
  hideNonTrendLines?: boolean;
  preserveStartEnd?: boolean;

  bars?: BarConfig<TItem, TDataKey>[];
  emptyMessage?: string;
  showLegend?: boolean;
  chartsWrapperClassName?: string;
};

type DataKey<TItem, TValue = any> = Exclude<keyof TItem | ((item: TItem) => TValue), Symbol>;

interface DataConfig<TItem extends object, TKey extends string | number = string> {
  dataKey: DataKey<TItem>;
  label: string;
  color: string;
  key: TKey;
  renderPreviewInTooltip?: RenderPreviewInTooltipFn<TItem>;
  renderStatsInTooltip?: RenderStatsInTooltipFn<TItem>;
}

export type LineConfig<TItem extends object, TKey extends string | number = string> = DataConfig<TItem, TKey> & {
  strokeWidth?: number;
  isTrend?: boolean;
  noLegend?: boolean;
};
export type BarConfig<TItem extends object, TKey extends string | number = string> = DataConfig<TItem, TKey> & {
  isTrend?: boolean;
  stackId?: string;
  noLegend?: boolean;
};

const yDomains: Record<string, YAxisProps['domain']> = {
  zero: [0, 'auto'],
  low: ['dataMin', 'auto'],
};

export default function TimeLineChart<
  TItem extends object,
  TXAxisValue = any,
  TDataKey extends string | number = string
>({
  data,
  highlighDataKey,
  bars,
  lines,
  formatXAxisValue,
  formatYAxisValue,
  xAxisDataKey,
  yAxisProps = {},
  xAxisProps = {},
  connectNulls = false,
  withDots = false,
  emptyMessage,
  yAxisStartFromMin = false,
  yAxisAllowDecimals = true,
  hideTrendLines = false,
  hideNonTrendLines = false,
  preserveStartEnd = false,
  showLegend,
  chartsWrapperClassName,
  ...wrapperProps
}: TimeLineChartProps<TItem, TXAxisValue, number, TDataKey>) {
  const renderPreviewInTooltipMap = useMemo(
    () =>
      (lines ?? bars)?.reduce<RenderPreviewInTooltipMap<TItem>>((acc, { key, renderPreviewInTooltip, isTrend }) => {
        if (!isTrend) {
          acc[String(key)] = renderPreviewInTooltip;
        }
        return acc;
      }, {}),
    [lines, bars]
  );

  const renderLineStatsInTooltipMap = useMemo(
    () =>
      (lines ?? bars)?.reduce<RenderStatsInTooltipMap<TItem>>((acc, { key, renderStatsInTooltip, isTrend }) => {
        if (!isTrend) {
          acc[String(key)] = renderStatsInTooltip;
        }
        return acc;
      }, {}),
    [lines, bars]
  );

  const xLabelFormatter = (value: TXAxisValue) => {
    return formatXAxisValue ? formatXAxisValue(value) : formatAsDate(value);
  };

  const yLabelFormatter = (value: number) => {
    return formatYAxisValue ? formatYAxisValue(value) : formatAsCount(value);
  };

  const Chart = getChartComponent({ lines, bars });

  const isEmpty = !data?.length;

  return (
    <ChartWrapper {...wrapperProps} className={chartsWrapperClassName} emptyMessage={emptyMessage} isEmpty={isEmpty}>
      <Chart className={classes.chart} data={data}>
        <CartesianGrid vertical={false} stroke="var(--gull-gray)" />
        <XAxis
          dataKey={xAxisDataKey}
          allowDuplicatedCategory={!!bars?.length}
          tickFormatter={xLabelFormatter}
          interval={preserveStartEnd ? 'preserveStartEnd' : undefined}
          axisLine={false}
          tickLine={false}
          tickMargin={12}
          {...xAxisProps}
        />
        <YAxis
          axisLine={false}
          tickLine={{ stroke: 'var(--gull-gray)' }}
          tickFormatter={yLabelFormatter}
          width={72}
          domain={yAxisStartFromMin ? yDomains.low : yDomains.zero}
          allowDecimals={yAxisAllowDecimals}
          {...yAxisProps}
        />
        <ReferenceLine y={0} stroke={'var(--black)'} strokeWidth={4} />
        <Tooltip
          wrapperStyle={{ zIndex: 990 }}
          labelFormatter={xLabelFormatter}
          formatter={yLabelFormatter}
          content={(tooltipProps) => (
            <TimeLineChartTooltip
              label={tooltipProps.label}
              labelFormatter={tooltipProps.labelFormatter}
              payload={tooltipProps.payload as DataPayload<TItem>[]}
              renderPreviewInTooltipMap={renderPreviewInTooltipMap}
              renderLineStatsInTooltipMap={renderLineStatsInTooltipMap}
            />
          )}
        />
        {bars?.reduce<ReactNode[]>((acc, { color, dataKey, key, label, isTrend, stackId, noLegend }) => {
          const isInFocus = highlighDataKey === key;
          const isOutOfFocus = isDefined(highlighDataKey) && !isInFocus;
          if (isTrend) {
            return acc;
          }
          const id = `${key}${noLegend ? '-noLegend' : ''}`;
          acc.push(
            <Bar
              key={id}
              stroke={color}
              fill={color}
              dataKey={dataKey}
              type="monotone"
              className={clsx({
                [classes.lineOrBar]: true,
                [classes.outOfFocus]: isOutOfFocus,
              })}
              name={label}
              id={id}
              stackId={stackId}
            />
          );
          return acc;
        }, [])}
        {lines?.map(({ color, dataKey, key, strokeWidth = 1, label, isTrend, noLegend }) => {
          const isInFocus = highlighDataKey === key;
          const isOutOfFocus = isDefined(highlighDataKey) && !isInFocus;
          const id = `${key}${isTrend ? '-trend' : ''}${noLegend ? '-noLegend' : ''}`;
          return (
            <Line
              key={id}
              stroke={color}
              strokeOpacity={isTrend ? 0.6 : 1}
              dataKey={dataKey}
              type="monotone"
              className={clsx({
                [classes.lineOrBar]: true,
                [classes.outOfFocus]: isOutOfFocus,
              })}
              activeDot={{ r: hideNonTrendLines || isTrend ? 0 : 7 }}
              strokeWidth={strokeWidth}
              {...(isTrend
                ? {
                    strokeLinecap: 'round',
                    strokeDasharray: '20 10',
                    dot: false,
                    display: hideTrendLines ? 'none' : undefined,
                  }
                : {
                    dot: withDots ? { fill: color, r: 2 } : false,
                    display: hideNonTrendLines ? 'none' : undefined,
                  })}
              name={label}
              id={id}
              animateNewValues
              connectNulls={connectNulls}
            />
          );
        })}
        {!!showLegend && (
          <Legend
            payload={[
              ...(lines
                ? getLegendPayloads({
                    items: lines,
                    type: 'line',
                  })
                : []),
              ...(bars
                ? getLegendPayloads({
                    items: bars,
                    type: 'square',
                  })
                : []),
            ]}
          />
        )}
      </Chart>
    </ChartWrapper>
  );
}

interface LegentPayload {
  value: string;
  type: LegendType;
  id: string;
  color?: string;
  inactive?: boolean;
}

type LegendType = 'line' | 'square';

function getLegendPayloads<TItem extends object, TDataKey extends string | number = string>({
  items,
  type,
}: {
  items: LineConfig<TItem, TDataKey>[];
  type: LegendType;
}) {
  return items?.reduce<LegentPayload[]>((acc, line) => {
    if (line.isTrend || line.noLegend) {
      return acc;
    }
    acc.push({
      value: line.label,
      type,
      id: String(line.key),
      color: line.color,
    });
    return acc;
  }, []);
}

function getChartComponent<TItem extends object, TDataKey extends string | number = string>({
  lines,
  bars,
}: {
  lines?: LineConfig<TItem, TDataKey>[];
  bars?: BarConfig<TItem, TDataKey>[];
}) {
  if (!bars) {
    return LineChart;
  }
  if (!lines) {
    return BarChart;
  }
  return ComposedChart;
}
