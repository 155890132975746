import { useCallback, useEffect, useState } from 'react';
import useToday from '../sci-ui-components/hooks/useToday';
import useAnalytics from '../features/analytics/useAnalytics';
import {
  DateRangeName,
  IsoDateRange,
  getNumberOfDaysInDateRange,
  getIsoStringDateRangeInDateRange,
} from '../sci-ui-components/utils/date';

type SetDateRangeFn = (dateRange: IsoDateRange) => void;

export default function useDateRangeState(
  defaultDateRange?: DateRangeName,
  customDateRange?: IsoDateRange
): [IsoDateRange, SetDateRangeFn] {
  const today = useToday();

  const [dateRange, setDateRangeInternal] = useState<IsoDateRange>(() =>
    customDateRange ? customDateRange : getIsoStringDateRangeInDateRange(today, defaultDateRange)
  );

  const { trackEvent } = useAnalytics();
  const setDateRange = useCallback<SetDateRangeFn>(
    (dateRange) => {
      setDateRangeInternal(dateRange);
      trackEvent({
        eventName: 'DATE_RANGE_CHANGED',
        startDate: dateRange[0],
        endDate: dateRange[1],
        daysInRange: getNumberOfDaysInDateRange(dateRange),
      });
    },
    [setDateRangeInternal, trackEvent]
  );

  useEffect(() => {
    if (customDateRange) {
      setDateRange(customDateRange);
    } else if (defaultDateRange) {
      setDateRange(getIsoStringDateRangeInDateRange(today, defaultDateRange));
    }
  }, [defaultDateRange, customDateRange, setDateRange, today]);

  return [dateRange, setDateRange];
}
