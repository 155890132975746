import { ReactNode, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import useToday from '../hooks/useToday';
import { DateRanges, formatISOIfExists, IsoDateRange, MaybeIsoDate, parseISOIfExists } from '../utils/date';
import DateRangeSelectorButton from './DateRangeSelectorButton';
import dateFnsConfig from './dateFnsConfig';

import classes from './DateRangeSelector.module.scss';

const DatePicker = generatePicker<Date>(dateFnsConfig);

const { RangePicker } = DatePicker;

export type DateRangeSelectorProps = {
  className?: string;
  buttonClassName?: string;
  startDate: MaybeIsoDate;
  endDate: MaybeIsoDate;
  disabled?: boolean;
  onChange: (newRange: IsoDateRange) => void;
  isDateDisabled?: (date: Date) => boolean;
  getDateRanges?: (today: Date) => DateRanges;
  renderSelector?: (param: {
    endDate: MaybeIsoDate;
    startDate: MaybeIsoDate;
    onOpen: () => void;
    onClose: () => void;
    onClear: () => void;
    disabled: boolean | undefined;
    id?: string;
  }) => ReactNode;
  id?: string;
  dropdownClassName?: string;
  dateRangeFormatStr?: string;
  dateRangeLabel?: string;
  dateSelectorContentClassName?: string;
};

export default function DateRangeSelector({
  className,
  buttonClassName,
  startDate,
  endDate,
  onChange,
  disabled,
  isDateDisabled,
  getDateRanges,
  renderSelector,
  id,
  dropdownClassName,
  dateRangeFormatStr,
  dateRangeLabel = 'PERIOD',
  dateSelectorContentClassName,
}: DateRangeSelectorProps) {
  const pickerRef = useRef<{
    blur: () => void;
  } | null>(null);
  const [open, setOpen] = useState(false);
  const today = useToday();
  const ranges = useMemo(() => {
    if (!getDateRanges) {
      return undefined;
    }
    return getDateRanges(today);
  }, [getDateRanges, today]);
  const handleCalendarChange = (newRange: [Date | null, Date | null] | null) => {
    if (!newRange) {
      onChange([null, null]);
    } else {
      const [newStartDate, newEndDate] = newRange;
      onChange([formatISOIfExists(newStartDate), formatISOIfExists(newEndDate)]);
    }
  };

  return (
    <div className={clsx(classes.datePicker, className)}>
      <RangePicker
        autoFocus
        disabledDate={isDateDisabled}
        ranges={ranges}
        open={open}
        onOpenChange={() => setOpen(false)}
        onChange={handleCalendarChange}
        value={[parseISOIfExists(startDate), parseISOIfExists(endDate)]}
        disabled={disabled}
        className={classes.hiddenAntInput}
        dropdownClassName={clsx(classes.dropdown, dropdownClassName)}
        picker="date"
        suffixIcon={null}
        separator={null}
        inputRender={() => null}
        bordered={false}
        tabIndex={-1}
        ref={(picker) => {
          pickerRef.current = { blur: () => picker?.blur() };
        }}
      />
      {renderSelector ? (
        renderSelector({
          disabled,
          endDate,
          onOpen: () => setOpen(true),
          onClear: () => handleCalendarChange([null, null]),
          onClose: () => pickerRef.current?.blur(),
          startDate,
          id,
        })
      ) : (
        <DateRangeSelectorButton
          endDate={endDate}
          onClear={() => handleCalendarChange([null, null])}
          startDate={startDate}
          onClick={() => setOpen((o) => !o)}
          disabled={disabled}
          id={id}
          className={buttonClassName}
          labelText={dateRangeLabel}
          dateRangeFormatStr={dateRangeFormatStr}
          contentClassName={dateSelectorContentClassName}
        />
      )}
    </div>
  );
}
